export default {
  data() {
    return {
      categoryNameEn: "",
      categoryNameEs: "",
      categoryType: "",
      itemsCategoryType: [],
      screenWidth: 0,
      texts: "",
      images: [],
      iTotalImages: 0,
      bLoading: false,
      sCategoryId: "1",
      showMessages: false,
      color: "#ffffff",
    };
  },
  beforeMount() {
    this.texts = FILE.categoryTexts[this.selectLanguage];
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  upload() {
    this.$refs.clearUpload.clear();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    this.$store.commit("setDialogAddCategory", false);
    this.categoryNameEn = "";
    this.categoryNameEs = "";
    this.color = "#ffffff";
    this.images = [];
    this.iTotalImages = 0;
  },
  methods: {
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 40;
      } else {
        this.screenWidth = 100;
      }
    },
    setImages(e) {
      this.images = e;
      this.iTotalImages = this.images.length;
    },
    getCategoryType() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types/`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.itemsCategoryType = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    addCategory() {
      this.bLoading = true;

      const params = {
        oCategories: {
          sCategoryTypeId: this.categoryType,
          sImageKey: "",
          aTranslations: [
            {
              sName: this.categoryNameEn,
              sLang: "en",
            },
            {
              sName: this.categoryNameEs,
              sLang: "sp",
            },
          ],
          sColorCode: this.color,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      if (this.images.length > 0) {
        DB.post(
          `${URI}/api/v1/${this.selectLanguage}/categories`,
          params,
          config
        )
          .then((response) => {
            this.sendImage(response);
          })
          .catch((error) => {
            this.bLoading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.bLoading = false;
        this.mixError(this.texts.upload.message);
      }
    },
    sendImage(resp) {
      var form = new FormData();
      form.append("image", this.images[0]);
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/categories/${resp.data.results.sCategoryId}`,
        form,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.close();
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((err) => {
          this.bLoading = false;
          this.mixError(err.response.data.message);
        });
    },
    close() {
      this.$store.commit("setDialogAddCategory", false);
      this.categoryNameEn = "";
      this.categoryNameEs = "";
      this.color = "#ffffff";
      this.images = [];
      this.iTotalImages = 0;
      this.$refs.clearUpload.clear();
    },
    emitClose() {
      this.$store.commit("setDialogAddCategory", false);
      this.categoryNameEn = "";
      this.categoryNameEs = "";
      this.color = "#ffffff";
      this.images = [];
      this.iTotalImages = 0;
      // this.$refs.clearUpload.clear();
    },
  },
  computed: {
    messages() {
      return this.showMessages ? ["Message"] : undefined;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    dialogAddCategory() {
      return this.$store.state.dialogAddCategory;
    },
    validateForm() {
      return (
        this.categoryNameEn !== "" &&
        this.categoryNameEs !== "" &&
        this.categoryType !== "" &&
        this.color !== "" &&
        this.categoryNameEn !== null &&
        this.categoryNameEs !== null &&
        this.categoryType !== null &&
        this.color !== null &&
        this.images.length > 0
      );
    },
  },
  watch: {
    dialogAddCategory: function() {
      if (this.dialogAddCategory) {
        this.$store.commit("setMenuSidebar", { active: false });

        this.texts = FILE.categoryTexts[this.selectLanguage];
        this.getCategoryType();
        this.sCategoryId = "1";
      }
    },
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.categoryTexts[this.selectLanguage];
      }
    },
  },
};
